<template>
  <div>
    <v-card>

      <v-toolbar style="margin-bottom: 5px" flat>
        <v-toolbar-title>
          <span class="headline"> Importação de Arquivo Financeiro </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon large @click="handleClose($event)">mdi-close</v-icon>
      </v-toolbar>
      <v-divider />

      <v-col cols="12" md="12" class=" pading ">
        <div class="d-flex flex-wrap justify-space-between">
          <label for="midia" style="cursor: pointer; width: 100%" class="caption d-flex justify-center flex-wrap border "
            title="Add logo cliente">
            <v-icon x-large color="primary" style="height: 200px; width: 100%" for="midia">mdi-cloud-upload</v-icon>
            <span class="mb-2"> Carregar Arquivo </span>
            <input id="midia" style="display: none" type="file" @change="uploadMidia($event)" />
          </label>

        </div>
      </v-col>
    </v-card>
  </div>
</template>

<script>
import {
  FinanceiroService
} from "../../services";

export default {
  name: "importacaoFinanceiro",
  data: () => ({
    formData: null,
    file: {},
    loadingMidia: false,
  }),
  methods: {
    handleClose() {
      this.$emit("onClose");
    },

    uploadMidia(event) {
      if (event && event.target && event.target.files.length > 0) {
        this.file = event.target.files[0];
        this.formData = new FormData();
        this.formData.append("file", this.file);
        this.formData.append("name", this.file.name);
        this.loadingMidia = true;
        this.$root.showInfo("Aguarde, Arquivo esta sendo verificado!");
        this.handleClose();
        FinanceiroService.uploadRelatorio(this.formData)
          .then((response) => {
            console.log(response)
            this.$root.showSucesso("Arquivo importado com sucesso!");
          })
          .catch((erro) => {
            this.$root.showErro(erro.data);
          })
          .finally(() => {
            this.loadingMidia = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.w-full {
  width: 100%;
}

.text-color-red {
  color: #e30613;
}

.color-detail {
  color: #757575 !important;
}

.border {
  border: 2px dashed gray;
}

.pading {
  padding-left: 12%;
  padding-right: 12%;
}
</style>
