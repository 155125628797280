<template>
  <div>
    <h2 class="ml-4 mt-2">Relatório de Ingressos</h2>
    <v-card class="mt-4 mx-4">
      <v-card-title> Escolha o Período</v-card-title>
      <v-col cols="12" class="d-flex">
        <v-col cols="4">
          <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="dataInicial"
            transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on }">
              <v-text-field v-model="formatedDataInicial" label="Data de início" prepend-icon="mdi-calendar" readonly
                v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="dataInicial" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false">
                Cancelar
              </v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(dataInicial)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="4">
          <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" :return-value.sync="dateTermino"
            transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on }">
              <v-text-field v-model="formatedDataFinal" label="Data de Termino" prepend-icon="mdi-calendar" readonly
                v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="dateTermino" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu2 = false">
                Cancelar
              </v-btn>
              <v-btn text color="primary" @click="$refs.menu2.save(dateTermino)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="4">
          <v-autocomplete v-model="produtoId" :items="getProdutos" item-text="descricao" item-value="id" multiple
            label="Eventos"></v-autocomplete>
        </v-col>
      </v-col>
      <v-col cols="12" class="d-flex justify-end">
        <v-btn color="primary" class="mr-4" @click="showDialogImportacao = true">
          Arquivo Cielo 
        </v-btn>
        <v-btn color="primary" @click="download"> Baixar</v-btn>
      </v-col>

      <v-dialog v-model="showDialogImportacao" persistent max-width="900" >
        <importacao-financeiro @onClose="showDialogImportacao = false" />
      </v-dialog>

    </v-card>

  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { FinanceiroService } from "../../services";
import importacaoFinanceiro from "./importacao-financeiro.vue";

export default {
  components: {
    importacaoFinanceiro
  },
  data() {
    return {
      dateTermino: "",
      menu: false,
      menu2: false,
      produtoId: null,
      dataInicial: "",
      showDialogPost: false,
      showDialogImportacao: false
    };
  },

  created() {
    this.setProdutosRelatorio();
  },
  computed: {
    formatedDataFinal() {
      return this.formatDate(this.dateTermino);
    },
    formatedDataInicial() {
      return this.formatDate(this.dataInicial);
    },
    ...mapGetters({
      getProdutos: "marketplace/getProdutosRelatorio",
    }),
  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    formatLocalDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${year}-${month}-${day}`;
    },

    salvarPost() {
      //this.closeDialogPost();
      this.getAllPostByPeriodo();
    },
    upload() {
      this.showDialogImportacao = true;
    },

    download() {
      let payload = {
        dataInicio: this.formatLocalDate(this.dataInicial),
        dataFim: this.formatLocalDate(this.dateTermino),
        produtoIds: this.produtoId,
      };
      FinanceiroService.downloadRelatorio(payload)
        .then((response) => {
          const url = URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute("download", "relatorio.xlsx");
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          if (error.status != 401) {
            this.$root.showErro(error.data);
          }
        })
        .finally(() => {
          this.loadingRelatorio = false;
        });
    },

    ...mapActions({
      setProdutosRelatorio: "marketplace/setProdutosRelatorio",
    }),
  },
};
</script>
